import { PureComponent } from 'react';
import VerticalPost from './VerticalPost';
import './VerticalList.css';

export default class VerticalList extends PureComponent<VerticalListProps> {
  render() {
    let verticalPost = null;
    const verticalPostData = this.props.posts;
    if (verticalPostData != null) {
      verticalPost = [];
      verticalPostData.forEach((post) => {
        verticalPost.push(<VerticalPost post={post} key={post.id.toString()} id={post.id.toString()} />);
      });
    }
    return (
      <div className="verticalPostContainer">
        {verticalPost}
      </div>
    );
  }
}
interface VerticalListProps {
  posts: any[] | null
}
