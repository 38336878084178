import { PureComponent } from 'react';
import Moment from 'react-moment';
import { truncateText } from '../../utils';
import 'moment/locale/nl';
import './VerticalPost.css';
import PostOverView from '../Overview/PostOverView';
import { CreateMarkup } from "../Utils";

export default class VerticalPost extends PureComponent<VerticalPostProps> {
  render() {
    const { post, id } = this.props;
    const modalID = post.id !== undefined ? post.id : id;
    let descriptionMarkup = CreateMarkup(this.props.post.description);

    return (
      <div className="row verticalPostRow gets-shadow--hover gets-rounded">
        <div className="col-md-12 col-xs-12 imageContainer">
          <div className="date-container">
            <div className="date">
              <Moment format="D" locale="nl">
                {post.date_created}
              </Moment>
            </div>
            <div className="month">
              <small>
                <Moment format="MMM" locale="nl">
                  {post.date_created}
                </Moment>
              </small>
            </div>
          </div>
          {post.photo_list_original.length > 0 ? (
            <img
              src={post.photo_list_original[0]}
              className="verticalImagePhoto"
              alt={post.title}
            />
          ) : (
            <div className="no_thumbnail">
              <i className="fa fa-newspaper-o fa-4x" aria-hidden="true"></i>
            </div>
          )}
        </div>
        <div className="col-md-12 col-xs-12 contentContainer">
          <h4
            className="postTitle"
            data-toggle="modal"
            data-target={`#modal_${modalID}`}
          >
            {post.title}
          </h4>
          <p>{truncateText(post.description, 18)}</p>
          <a
            className="btn btn-default documentBtn verticalBtn"
            data-toggle="modal"
            data-target={`#modal_${modalID}`}
          >
            Lees meer{' '}
          </a>
        </div>

        <div
          className="modal fade"
          id={`modal_${modalID}`}
          tabIndex={-1}
          role="dialog"
          aria-labelledby="myModalLabel"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">{post.title}</h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="newsfeed-modal modal-body">
                <PostOverView post={post} descriptionMarkup={descriptionMarkup} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
interface VerticalPostProps {
  post: any,
  id: string
}