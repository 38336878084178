import Video from './Video';

export default function VideoList({videos}: VideoListProps) {
  return (
    <div className="videoListContainer">
      {videos &&
        videos.map((video, index) => <Video src={video} key={index} />)}
    </div>
  );
}


//TODO: YURI RECH
// Implement better interface
export interface VideoListProps {
  videos: string[]
}
