import { PureComponent } from 'react';
import PostOverView from './PostOverView';
import './PostOverViewList.css';
import { CreateMarkup } from "../Utils";


export default class PostOverViewList extends PureComponent<PostOverviewListProps> {
  render() {
    let postContainer = null;
    const postData = this.props.postData;
    if (postData !== null) {
      postContainer = [];
      postData.forEach((value, key) => {
        let descriptionMarkup = CreateMarkup(value.description);
        postContainer.push(<PostOverView key={key.toString()} post={value} descriptionMarkup={descriptionMarkup} />);
      });
    }

    return (
      <div className="postListContainer">
        {postContainer}
      </div>
    );
  }
}

interface PostOverviewListProps {
  postData: any[] | null
}
