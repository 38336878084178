import { PureComponent, Fragment } from 'react';
import Moment from 'react-moment';
import 'moment/locale/nl';
import './Post.css';
import PostOverView from '../Overview/PostOverView';
import { CreateMarkup } from "../Utils";

// @ts-ignore
Moment.globalLocale = 'nl';

export default class Post extends PureComponent<PostProps> {
  render() {
    const { post, id } = this.props;
    console.log(post)
    let imageThumbnail;
    let videoUrl = null;
    let videoThumbnail = null;
    let isVideoPresent = false;
    if (post._first_photo !== null) {
      imageThumbnail = post.photo_list[0];
    }
    const title = post.title
      .split(' ')
      .splice(0, 5)
      .join(' ');
    const isPhotosPresent = post._photo_count > 0;
    const isDocumentPresent = post._document_count > 0;
    if (post.videos !== undefined) {
      isVideoPresent = post.videos.length > 0;
    }
    if (post.uploaded_video_urls !== undefined) {
      isVideoPresent = post.uploaded_video_urls.length > 0;
    }
    const mediaPresent = isPhotosPresent || isDocumentPresent || isVideoPresent;
    let modalID = post.id !== undefined ? post.id : id;

    if (post.uploaded_video_urls && post.uploaded_video_thumbnails) {
      videoUrl = post.uploaded_video_urls[0];
      videoThumbnail = post.uploaded_video_thumbnails[0];
    }
    let showPhoto = false;
    if (videoUrl !== null && post._first_photo !== null) {
      showPhoto = true;
    }
    let descriptionMarkup = CreateMarkup(this.props.post.description);


    return (
      <Fragment>
        <div className="listPostRowContainer mb-3 " data-toggle="modal" data-target={`#modal_${modalID}`}>
          <div className="card gets-rounded gets-shadow--hover">
            <div className="row">
              <div className="col-12 col-md-8">
                <div className="card-body">
                  <h4 className="card-title text-truncate mb-1">
                    <a className="listPostTitle inline cboxElement">{title}</a>
                  </h4>
                  <div className="card-text text-truncate dateContainer">
                    <Moment format="D-MM-YYYY" locale="nl">
                      {post.date_created}
                    </Moment>
										&nbsp;in {post._community_name} {mediaPresent && <span>&nbsp;|</span>}
                    {isPhotosPresent && <i className="fa fa-camera postIcons" aria-hidden="true"></i>}
                    {isDocumentPresent && <i className="fa fa-file postIcons" aria-hidden="true"></i>}
                    {isVideoPresent && <i className="fa fa-video-camera postIcons" aria-hidden="true"></i>}
                  </div>
                </div>
              </div>
              <div className="col-md-4 d-none d-md-flex news-thumbnail-thumb">
                {post._first_photo && <img className="card-img-top listPostImage" src={imageThumbnail} alt={title} />}
                {!showPhoto && videoUrl && <img src={videoThumbnail} className="card-img-top listPostImage" alt="" />}
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id={`modal_${modalID}`} tabIndex={-1} role="dialog" aria-labelledby="myModalLabel">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">{post.title}</h4>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="newsfeed-modal modal-body">
                <PostOverView post={post} key={post.id} descriptionMarkup={descriptionMarkup} />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}


interface PostProps {
  post: any,
  id: string
}
