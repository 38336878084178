import { PureComponent } from 'react';
import Moment from 'react-moment';
import 'moment/locale/nl';
import './PostOverView.css';
import placeholder from '../../images/placeholder.jpg';
import Event from './Event';
import DocumentList from './Document/DocumentList';
import VideoList from './Video/VideoList';

import PhotoAlbum from "react-photo-album";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

export default class PostOverView extends PureComponent<PostOverViewProps, PostOverViewState> {
  constructor(props: PostOverViewProps) {
    super(props);
    this.state = {
      documentList: [],
      photoList: [],
      index: -1
    };

    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    if (this.props.post._document_count !== 0 && this.props.post.documents) {
      this.getAllDocs(this.props.post.documents);
    }
    if (this.props.post._photo_count !== 0 && this.props.post.photos) {
      this.getAllImages(this.props.post.photos);
    }
  }

  getAllImages(URL: any) {
    return fetch(URL)
      .then(response => {
        return response.json();
      })
      .then(json => {
        const newPhotoList = [...this.state.photoList, ...json.results];
        this.setState({ photoList: newPhotoList });
        const nextURL = json.next;
        if (!nextURL) {
          return;
        } else {
          this.getAllImages(nextURL);
        }
      });
  }

  getAllDocs(URL: any) {
    return fetch(URL)
      .then(response => {
        return response.json();
      })
      .then(json => {
        const newDocumentList = [...this.state.documentList, ...json.results];
        this.setState({ documentList: newDocumentList });
        const nextURL = json.next;
        if (!nextURL) {
          return;
        } else {
          this.getAllDocs(nextURL);
        }
      });
  }

  handleClick = ({ index }) => {
    this.setState({ index });
  };

  handleClose = () => {
    this.setState({ index: -1 });
  };
  render() {
    const { index } = this.state;
    const post = this.props.post;
    const descriptionMarkup = this.props.descriptionMarkup;
    const photos = this.state.photoList;
    let videoUrl = null;
    const IMAGES: any[] = [];
    if (photos !== null) {
      photos.forEach((value) => {
        const image = {
          src: value.picture,
          thumbnail: value.thumbnails,
          width: 1200,
          height: 800
        };
        IMAGES.push(image);
      });
    }
    let author;
    let authorPicture;
    if (post.posted_by) {
      if (post.posted_by.first_name) {
        author = `${post.posted_by.first_name} ${post.posted_by.last_name}`;
      } else {
        author = `${post.posted_by.last_name}`;
      }
      authorPicture = post.posted_by.picture || placeholder;
    } else {
      authorPicture = placeholder;
    }
    const community = post._community_name;
    // Video related code
    if (post.uploaded_video_urls && post.uploaded_video_urls.length > 0) {
      videoUrl = post.uploaded_video_urls[0];
    }
    let videoCode = null;
    let youTubeVideoUrl = null;
    if (post.videos && post.videos.length > 0) {
      const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
      const match = post.videos[0].url.match(regExp);
      if (match && match[7].length === 11) {
        videoCode = match[7];
        youTubeVideoUrl = `//www.youtube.com/embed/${videoCode}`;
      }
    }
    // Event related code
    const event = post.event;

    //Lightbox

    // @ts-ignore


    return (
      <div className="card mb-3 postContainer postOverviewContainer">
        <div className="postOverviewheader bg-primary b-primary">
          <div className="card-header">
            <h4 className="card-title postTitle my-1">{post.title}</h4>
          </div>
        </div>
        <div className="card-body postDetailContainer">
          <div className="row d-flex flex-row authorDetailsTop mb-3 pr-3 pl-3">
            <div className="authorPicture pr-3">
              <img src={authorPicture} className="postedbyImg" alt="" />
            </div>
            <div className="authorDetailContainer ">
              <span className="authorDetails">
                {author} in {community}
              </span>
              <span>
                op{' '}
                <Moment format="DD MMM YYYY" locale="nl">
                  {post.date_created}
                </Moment>
              </span>
            </div>
          </div>
          <div dangerouslySetInnerHTML={descriptionMarkup} />

          <DocumentList documents={this.state.documentList} />
          {event && <Event event={event} />}

          <>
            <PhotoAlbum
              photos={IMAGES}
              layout="rows"
              targetRowHeight={150}
              onClick={this.handleClick}
            />

            <Lightbox
              slides={IMAGES}
              open={index >= 0}
              index={index}
              close={this.handleClose}
              plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
            />
          </>

          {youTubeVideoUrl && (
            <iframe
              title={post.title}
              width="560"
              height="315"
              src={youTubeVideoUrl}
              frameBorder="0"
              allowFullScreen
            />
          )}
          {videoUrl && <VideoList videos={post.uploaded_video_urls} />}
        </div>
      </div>
    );
  }
}


//TODO: Better typing

interface PostOverViewProps {
  post: any,
  descriptionMarkup?: {__html: string},
}

interface PostOverViewState {
  documentList: any[],
  photoList: any[],
  index: number
}
