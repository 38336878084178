import { PureComponent, Fragment, MouseEvent } from 'react';
import './ListApp.css';
import PostList from './PostList';
import {GlobalComponentProps} from "../../ts/interfaces/global_interfaces.ts";

const getAPIURL = (baseURL: string, communityId: string | undefined, show: number, numberOfItems: number) => {
  let finalURL = `${baseURL}/apiv1/public/${communityId}/post/?number_of_items=${numberOfItems}`;
  if (show === 1) {
    finalURL = `${baseURL}/apiv1/public/${communityId}/post/?community_and_descendants=true&number_of_items=${numberOfItems}`;
  } else if (show === 3) {
    finalURL = `${baseURL}/apiv1/public/${communityId}/post/?only_descendants=true&number_of_items=${numberOfItems}`;
  }
  return finalURL;
};

export default class ListApp extends PureComponent<GlobalComponentProps, ListAppState> {
  private requestURL: string;
  constructor(props:GlobalComponentProps) {
    super(props);
    let baseURL = 'https://api.socialschools.eu';
    const communityId = this.props.communityId;
    const numberOfItems = parseInt(this.props.itemCount as string, 10);
    const show = parseInt(this.props.show as string, 10);
    const moreURL = this.props.url;

    this.requestURL = getAPIURL(baseURL, communityId, show, numberOfItems);
    this.state = {
      postsList: null,
      next: null,
      previous: null,
      moreURL,
    };
  }

  componentDidMount() {
    const self = this;
    fetch(this.requestURL)
      .then(response => {
        if (response.status === 200) {
          return response.json();
        }
        return {
          results: [],
          next: null,
          previous: null,
        };
      })
      .then(json => {
        self.setState({
          postsList: json.results,
          next: json.next,
          previous: json.previous,
        });
      });
  }

  loadPrevious(event: MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();
    const requestURL = this.state.previous;
    if (requestURL !== null) {
      fetch(requestURL)
        .then(response => {
          if (response.status === 200) {
            return response.json();
          }
          return {
            results: [],
            next: null,
            previous: null,
          };
        })
        .then(json => {
          // const newPostList = [...this.state.postsList, ...json.results];
          this.setState({
            postsList: json.results,
            next: json.next,
            previous: json.previous,
          });
        });
    }
  }

  loadNext(event: MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();
    const requestURL = this.state.next;
    if (requestURL !== null) {
      fetch(requestURL)
        .then(response => {
          if (response.status === 200) {
            return response.json();
          }
          return {
            results: [],
            next: null,
            previous: null,
          };
        })
        .then(json => {
          // const newPostList = [...this.state.postsList, ...json.results];
          this.setState({
            postsList: json.results,
            next: json.next,
            previous: json.previous,
          });
        });
    }
  }

  loadMore(event: MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();
    const requestURL = this.state.next;
    if (requestURL !== null) {
      fetch(requestURL)
        .then(response => {
          return response.json();
        })
        .then(json => {
          if(!!this.state.postsList) {
            const newPostList = [...this.state.postsList, ...json.results];
            this.setState({ postsList: newPostList, next: json.next });
          } else {
            this.setState({ postsList: [...json.results], next: json.next });
          }
        });
    }
  }

  render() {
    const nextbntClass = 'btn btn-default nextBtn loadMore mt-btn';
    const prevbtnClass = 'btn btn-default prevBtn loadMore mt-btn';
    const isEmpty = this.state.postsList === null || this.state.postsList.length === 0;
    return (
      <div className="postListContainerSuper">
        {!isEmpty && (
          <Fragment>
            <PostList postData={this.state.postsList} />
            <div className="pagination-wrapper center clearfix">
              {this.state.previous && (
                <a
                  className={prevbtnClass}
                  onClick={this.loadPrevious.bind(this)}
                  aria-roledescription="loadPrevious"
                  role="previousBtn"
                >
                  <i className="fa fa-chevron-left" aria-hidden="true" />
                </a>
              )}
              {this.state.moreURL && (
                <a href={this.state.moreURL} className="mt-btn btn btn-default btn-apps btn-apps-messages">
                  Bekijk nieuwsoverzicht
                </a>
              )}
              {this.state.next && (
                <a
                  className={nextbntClass}
                  onClick={this.loadNext.bind(this)}
                  aria-roledescription="loadNext"
                  role="nextBtn"
                >
                  <i className="fa fa-chevron-right" aria-hidden="true" />
                </a>
              )}
            </div>
          </Fragment>
        )}
        {isEmpty && <p>Er zijn geen nieuwe nieuwsberichten.</p>}
      </div>
    );
  }
}
interface ListAppState {
  postsList: any[] | null,
  next: string | null,
  previous: string | null,
  moreURL: string | undefined,
}
