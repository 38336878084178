import './Document.css';

export default function Document({ document }: DocumentProps) {
  return (
    <a
      className="btn btn-default documentBtn btn-document-link text-wrap mw-100"
      target="_blank"
      rel="noreferrer"
      id={document.id}
      href={document.document}
    >
      {document.document_file}
      <i className="fa fa-file-pdf-o ml-1" aria-hidden="true"></i>
    </a>
  );
}

interface Document {
    id: string;
    document: string;
    document_file: string;
};

interface DocumentProps {
  document: Document;
}
