import React from 'react';
import getAPIURL from '../../API/getApiUrl';
import HorizontalPostListist from '../Horizontal/HorizontalPostList';
import './CardOverview.css';
import {GlobalComponentProps} from "../../ts/interfaces/global_interfaces.ts";


class CardOverview extends React.PureComponent<GlobalComponentProps, CardOverviewState> {
private requestURL: any;
constructor(props: CardOverviewProps) {
  super(props);
  const { communityId, show, itemCount } = this.props;
  this.requestURL = getAPIURL(communityId, show, itemCount);
  this.state = {
  postsList: [],
  nextUrl: '',
  loading: true,
};
}

  loadMorePosts = () => {
    this.setState({ ...this.state, loading: true });
    fetch(this.state.nextUrl)
      .then(res => {
        return res.json();
      })
      .then(json => {
        this.setState({
          ...this.state,
          postsList: [...this.state.postsList, ...json.results],
          nextUrl: json.next,
          loading: false,
        });
      })
      .catch(err => console.log(err));
  };

  render() {
    const { loading, postsList, nextUrl } = this.state;
    console.log(postsList);
    return (
      <div className="news__items news__items-horizontal news__items-card-overview mt-3 mb-4">
        {postsList?.length === 0 && !loading && (
          <p>Er zijn geen nieuwe nieuwsberichten.</p>
        )}
        {loading && <p>Nieuwsberichten laden...</p>}

        {postsList?.length > 0 && <HorizontalPostListist posts={postsList} />}
        <div className="pagination-wrapper">
          {nextUrl && (
            <a
              className="btn b-theme c-white mx-auto"
              onClick={this.loadMorePosts}
              aria-roledescription="loadNext"
              // disabled={loading}
            >
              Meer berichten laden{' '}
              {loading ? (
                <i className="fas fa-spinner fa-spin"></i>
              ) : (
                <i className="fa fa-chevron-down" aria-hidden="true" />
              )}
            </a>
          )}
        </div>
      </div>
    );
  }

  componentDidMount() {
    fetch(this.requestURL)

  .then(response => {
      return response.json();
    })
      .then(json => {
        this.setState({
          ...this.state,
          postsList: json.results,
          nextUrl: json.next,
          loading: false,
        });
      });
  }
}

export default CardOverview;

// TODO: Convert to function component and better typing YURI RECH
interface CardOverviewProps  {
  communityId: string | undefined;
  show: string | undefined;
  itemCount: string | undefined;
  hideEvents: string | undefined;
  baseURLType: string | undefined;
  url: string | undefined;
}

interface CardOverviewState{
  postsList: any[]; // Replace 'any' with the type of 'postsList'
  nextUrl: string;
  loading: boolean;
}