import { PureComponent, Fragment, Suspense, MouseEvent } from 'react';
import HorizontalPostList from './HorizontalPostList';
import {GlobalComponentProps} from "../../ts/interfaces/global_interfaces.ts";
import './HorizontalApp.css'

// TODO: YURI RECH. I've see duplicated code, maybe make it one global function
const getAPIURL = (baseURL: string, communityId: string | undefined, show: number, numberOfItems: number) => {
  let finalURL = `${baseURL}/apiv1/public/${communityId}/post/?number_of_items=${numberOfItems}`;
  if (show === 1) {
    finalURL = `${baseURL}/apiv1/public/${communityId}/post/?community_and_descendants=true&number_of_items=${numberOfItems}`;
  } else if (show === 3) {
    finalURL = `${baseURL}/apiv1/public/${communityId}/post/?only_descendants=true&number_of_items=${numberOfItems}`;
  }
  return finalURL;
};

export default class HorizontalApp extends PureComponent<GlobalComponentProps, HorizontalAppState> {
  private requestURL: string;
  constructor(props: GlobalComponentProps) {
    super(props);
    let baseURL = 'https://api.socialschools.eu';
    const communityId = this.props.communityId;
    const numberOfItems = parseInt(this.props.itemCount as string, 10);
    const show = parseInt(this.props.show as string, 10);
    const moreURL = this.props.url;

    this.requestURL = getAPIURL(
      baseURL,
      communityId,
      show,
      numberOfItems
    );
    this.state = {
      postsList: null,
      next: null,
      previous: null,
      moreURL
    };
  }

  componentDidMount() {
    const self = this;
    fetch(this.requestURL)
      .then(response => {
        return response.json();
      })
      .then(json => {
        self.setState({
          postsList: json.results,
          next: json.next,
          previous: json.previous
        });
      });
  }

// TODO: YURI RECH. I've see duplicated code, maybe make it one global function

  loadPrevious(event: MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();
    const requestURL = this.state.previous;
    if (requestURL !== null) {
      fetch(requestURL)
        .then(response => {
          return response.json();
        })
        .then(json => {
          // const newPostList = [...this.state.postsList, ...json.results];
          this.setState({
            postsList: json.results,
            next: json.next,
            previous: json.previous
          });
        });
    }
  }

// TODO: YURI RECH. I've see duplicated code, maybe make it one global function
  loadNext(event: MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();
    const requestURL = this.state.next;
    if (requestURL !== null) {
      fetch(requestURL)
        .then(response => {
          return response.json();
        })
        .then(json => {
          // const newPostList = [...this.state.postsList, ...json.results];
          this.setState({
            postsList: json.results,
            next: json.next,
            previous: json.previous
          });
        });
    }
  }

  render() {
    const nextbntClass = 'btn btn-default nextBtn loadMore pull-right mt-btn';
    const prevbtnClass = 'btn btn-default prevBtn loadMore mt-btn';
    const isEmpty =
      this.state.postsList === null || this.state.postsList.length === 0;
    return (
      <Fragment>
        <div className="news__items news__items-horizontal mt-3 mb-4">
          <Suspense fallback={<div>Loading Posts...</div>}>
            {!isEmpty && (
              <>
                <HorizontalPostList
                  posts={this.state.postsList}
                />
                <div className="pagination-wrapper center clearfix">
                  <div className="previousContainer pull-left">
                    {this.state.previous && (
                      <a
                        className={prevbtnClass}
                        onClick={this.loadPrevious.bind(this)}
                        aria-disabled={!!this.state.previous}
                        aria-roledescription="loadPrevious"
                      >
                        <i className="fa fa-chevron-left" aria-hidden="true" />
                      </a>
                    )}
                  </div>
                  {this.state.moreURL && (
                    <a
                    href={this.state.moreURL}
                  className="btn btn-default btn-apps btn-apps-messages mt-btn"
                    >
                      Bekijk nieuwsoverzicht
                    </a>
                  )}
                  <div className="nextContainer pull-right">
                    {this.state.next && (
                      <a
                        className={nextbntClass}
                        onClick={this.loadNext.bind(this)}
                        aria-disabled={!!this.state.next}
                        aria-roledescription="loadNext"
                      >
                        <i className="fa fa-chevron-right" aria-hidden="true" />
                      </a>
                    )}
                  </div>
                </div>
              </>
            )}
            {isEmpty && <p>Er zijn geen nieuwe nieuwsberichten.</p>}
          </Suspense>
        </div>
      </Fragment>
    );
  }
}

interface HorizontalAppState {
  postsList: any[] | null,
  next: string | null,
  previous: string | null,
  moreURL: string | undefined,
}