
export default function Pagination({ url, loadPosts, previousUrl, nextUrl }: PaginationProps) {
  const nextbntClass = 'btn btn-default nextBtn loadMore';
  const prevbtnClass = 'btn btn-default prevBtn loadMore';
  return (
    <div className="pagination-wrapper">
      {previousUrl && (
        <button className={prevbtnClass} onClick={() => loadPosts('previous')}>
          <i className="fa fa-chevron-left" aria-hidden="true" />
        </button>
      )}
      {url && (
        <a
          href={url}
          className="mt-btn btn btn-default btn-apps btn-apps-messages"
        >
          Bekijk nieuwsoverzicht
        </a>
      )}
      {nextUrl && (
        <button className={nextbntClass} onClick={() => loadPosts('next')}>
          <i className="fa fa-chevron-right" aria-hidden="true" />
        </button>
      )}
    </div>
  );
}

interface PaginationProps {
  url?: string,
  loadPosts: (page: string) => void;
  previousUrl: string;
  nextUrl: string;
}