export function truncateText(str: string, num: number) {
  // remove common (not all) Markdown
  // remove Headings
  str = str.replace(/###### /g, "");
  str = str.replace(/##### /g, "");
  str = str.replace(/#### /g, "");
  str = str.replace(/### /g, "");
  str = str.replace(/## /g, "");
  str = str.replace(/# /g, "");

  // remove formatting
  str = str.replace(/\*\*\*/g, "");
  str = str.replace(/\*\*/g, "");
  str = str.replace(/\* /g, ""); // bullet
  str = str.replace(/\*/g, "");
  str = str.replace(/___/g, "");
  str = str.replace(/__/g, "");
  str = str.replace(/_/, "");

  // block quote
  str = str.replace(/> /g, "")

  // bullets
  str = str.replace(/- /g, "")
  

  // transform the string into a array to check its length
  const words = str.split(' ');

  // if there are less words then what we want to truncate we just return the string
  if (words.length <= num) return str;

  // otherwise we slice the array on the nth word, join the array with a space and add the dots
  return words.slice(0, num).join(' ') + '...';
}
