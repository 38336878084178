import { PureComponent } from 'react';
import HorizontalPost from './HorizontalPost';
import './HorizontalPostList.css';

export default class HorizontalPostList extends PureComponent<HorizontalPostListProps> {
  render() {
    const { posts } = this.props;

    return (
      <div className="news__list">
        {posts !== null &&
          posts.map((post: {id: number}) => (
            <HorizontalPost
              post={post}
              key={post.id.toString()}
              id={post.id.toString()}
            />
          ))}
      </div>
    );
  }
}

//TODO: develop better typying YURI RECH
interface HorizontalPostListProps {
  posts: any[] | null
}
