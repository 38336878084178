import 'es6-shim';
import { createRoot } from "react-dom/client";
import './index.css';
import ListApp from './Apps/List/ListApp';
import HorizontalApp from './Apps/Horizontal/HorizontalApp';
import VerticalApp from './Apps/Vertical/VerticalApp';
import OverViewApp from './Apps/Overview/OverViewApp';
import CardOverview from './Apps/CardOverview/CardOverview';
import Compact from './Apps/Compact/Compact';


// add comment to generate a new build
function renderList() {
  const listRoot:NodeListOf<HTMLElement> = document.querySelectorAll('.listroot');
  listRoot.forEach(elem => {
    createRoot(elem).render(
        <ListApp
            communityId={elem.dataset.communityId}
            itemCount={elem.dataset.itemCount}
            show={elem.dataset.show}
            hideEvents={elem.dataset.hideEvents}
            baseURLType={elem.dataset.baseURLType}
            url={elem.dataset.url}
        />
    );
  });
}

function renderOverview() {
  const overviewRoot:NodeListOf<HTMLElement> = document.querySelectorAll('.overviewroot');
  overviewRoot.forEach(elem => {
    createRoot(elem).render(
      <OverViewApp
        communityId={elem.dataset.communityId}
        itemCount={elem.dataset.itemCount}
        show={elem.dataset.show}
        hideEvents={elem.dataset.hideEvents}
        baseURLType={elem.dataset.baseURLType}
      />
    );
  });
}

function renderVertical() {
  const verticalRoot:NodeListOf<HTMLElement> = document.querySelectorAll('.verticalroot');
  verticalRoot.forEach(elem => {
    createRoot(elem).render(
      <VerticalApp
        communityId={elem.dataset.communityId}
        itemCount={elem.dataset.itemCount}
        show={elem.dataset.show}
        hideEvents={elem.dataset.hideEvents}
        baseURLType={elem.dataset.baseURLType}
        url={elem.dataset.url}
      />
    );
  });
}

function renderHorizontal() {
  const horizontalRoot:NodeListOf<HTMLElement> = document.querySelectorAll('.horizontalroot');
  horizontalRoot.forEach(elem => {
    createRoot(elem).render(
      <HorizontalApp
        communityId={elem.dataset.communityId}
        itemCount={elem.dataset.itemCount}
        show={elem.dataset.show}
        hideEvents={elem.dataset.hideEvents}
        baseURLType={elem.dataset.baseURLType}
        url={elem.dataset.url}
      />
    );
  });
}

function renderCardOverview() {
  const cardOverviewRoot:NodeListOf<HTMLElement> = document.querySelectorAll('.cardOverview');
  cardOverviewRoot.forEach(CardOverViewRoot => {
    let {
      communityId,
      itemCount,
      show,
      hideEvents,
      baseURLType,
      url,
    } = CardOverViewRoot.dataset;
    createRoot(CardOverViewRoot).render(
      <CardOverview
        communityId={communityId}
        itemCount={itemCount}
        show={show}
        hideEvents={hideEvents}
        baseURLType={baseURLType}
        url={url}
      />
    );
  });
}

function renderCompact() {
  const compactRoot:NodeListOf<HTMLElement> = document.querySelectorAll('.compactroot');
  compactRoot.forEach(elem => {
    createRoot(elem).render(
      <Compact
        communityId={elem.dataset.communityId}
        itemCount={elem.dataset.itemCount}
        show={elem.dataset.show}
        hideEvents={elem.dataset.hideEvents}
        baseURLType={elem.dataset.baseURLType}
        url={elem.dataset.url}
      />
    );
  });
}

renderCardOverview();
renderList();
renderOverview();
renderVertical();
renderHorizontal();
renderCompact();

document.addEventListener('DOMContentLoaded', () => {
  // Handler when the DOM is fully loaded
  if (window.CMS !== undefined) {
    window.CMS.$(window).on('cms-content-refresh', () => {
      renderCardOverview();
      renderList();
      renderOverview();
      renderVertical();
      renderHorizontal();
      renderCompact();
    });
  }
});
