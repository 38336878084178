import { PureComponent, Fragment, MouseEvent } from 'react';
import VerticalList from './VerticalList';
import {GlobalComponentProps} from "../../ts/interfaces/global_interfaces.ts";
import getAPIURL from "../../API/getApiUrl.ts";

//TODO: YURI RECH
//Check if there's duplicate code in VerticalApp.tsx
export default class VerticalApp extends PureComponent<GlobalComponentProps, VerticalAppState> {
  private requestURL: string;
  constructor(props: GlobalComponentProps) {
    super(props);
    let baseURL = 'https://api.socialschools.eu';

    const {
      communityId,
      show,
      itemCount: numberOfItems,
      url: moreURL
    } = this.props;

    this.requestURL = getAPIURL(
      communityId,
      show,
      numberOfItems,
      baseURL
    );
    this.state = {
      postsList: null,
      next: null,
      previous: null,
      moreURL
    };
  }

  componentDidMount() {
    const self = this;
    fetch(this.requestURL)
      .then(response => {
        return response.json();
      })
      .then(json => {
        self.setState({
          postsList: json.results,
          next: json.next,
          previous: json.previous
        });
      });
  }

  loadPrevious(event: MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();
    const requestURL = this.state.previous;
    if (requestURL !== null) {
      fetch(requestURL)
        .then(response => {
          return response.json();
        })
        .then(json => {
          // const newPostList = [...this.state.postsList, ...json.results];
          this.setState({
            postsList: json.results,
            next: json.next,
            previous: json.previous
          });
        });
    }
  }

  loadNext(event: MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();
    const requestURL = this.state.next;
    if (requestURL !== null) {
      fetch(requestURL)
        .then(response => {
          return response.json();
        })
        .then(json => {
          // const newPostList = [...this.state.postsList, ...json.results];
          this.setState({
            postsList: json.results,
            next: json.next,
            previous: json.previous
          });
        });
    }
  }

  render() {
    const nextbntClass = 'btn btn-default nextBtn loadMore nextBtn mt-btn';
    const prevbtnClass = 'btn btn-default prevBtn loadMore prevBtn mt-btn';
    const isEmpty =
      this.state.postsList === null || this.state.postsList.length === 0;
    return (
      <div className="verticalContainer">
        {!isEmpty && (
          <VerticalList
            posts={this.state.postsList}
          />
        )}
        {isEmpty && <p>Er zijn geen nieuwe nieuwsberichten.</p>}
        {!isEmpty && (
          <Fragment>
            <div className="pagination-wrapper center clearfix">
              {this.state.previous && (
                <a
                  className={prevbtnClass}
                  onClick={this.loadPrevious.bind(this)}
                  aria-roledescription="loadPrevious"
                >
                  <i className="fa fa-chevron-left" aria-hidden="true" />
                </a>
              )}
              {this.state.moreURL && (
                <a
                  href={this.state.moreURL}
                  className="btn btn-default btn-apps btn-apps-messages mt-btn"
                >
                  Bekijk nieuwsoverzicht
                </a>
              )}
              {this.state.next && (
                <a
                  className={nextbntClass}
                  onClick={this.loadNext.bind(this)}
                  aria-roledescription="loadNext"
                >
                  <i className="fa fa-chevron-right" aria-hidden="true" />
                </a>
              )}
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}

interface VerticalAppState {
  postsList: any[] | null,
  next: string | null,
  previous: string | null,
  moreURL: string | undefined
}