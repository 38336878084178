import { PureComponent, Fragment } from 'react';
import Moment from 'react-moment';
import 'moment/locale/nl';
import createDOMPurify from 'dompurify';
import { truncateText } from '../../utils';
import './HorizontalPost.css';
import PostOverView from '../Overview/PostOverView';
import { CreateMarkup } from '../Utils';

export default class HorizontalPost extends PureComponent<HorizontalPostProps> {

  render() {
    const post = this.props.post;
    const key = this.props.id;
    const modalID = post.id !== undefined ? post.id : key;
    const DOMPurify = createDOMPurify();
    let descriptionMarkup = CreateMarkup(this.props.post.description);
    let descriptionMarkupHtml = descriptionMarkup.__html;
    let isStartsWithP = descriptionMarkupHtml.startsWith('<p>') ? true : false;
    let purifiedDescription = DOMPurify.sanitize(descriptionMarkupHtml);
    let descriptionInWords = purifiedDescription.split(" ");
    let limitedDescription = descriptionInWords.length > 18 ? descriptionInWords.slice(0, 18).join(" ") + "..." : descriptionInWords.join(" ");

    return (
      <Fragment>
        <div className="news__container">
          <div className="news gets-shadow gets-rounded">
            <div className="news__header">
              {post.photo_list_original.length > 0 ? (
                <img
                  src={post.photo_list_original[0]}
                  className="horizontalPostPhoto"
                  alt={post.title}
                />
              ) : (
                <div className="no_thumbnail">
                  <i className="fa fa-newspaper-o fa-3x" aria-hidden="true"></i>
                </div>
              )}
            </div>
            <div className="news__body">
              <h4
                className="news__title postTitle hyphens mb-1"
                data-toggle="modal"
                data-target={`#modal_${modalID}`}
                lang="nl"
              >
                {post.title}
              </h4>
              <span className="text-muted post-date">
                <Moment format="D MMM YYYY" locale="nl">
                  {post.date_created}
                </Moment>
              </span>
              { isStartsWithP ?
                <div className="news__text hyphens" dangerouslySetInnerHTML={{__html:limitedDescription}}></div> :
                <p className="news__text hyphens">
                  {truncateText(post.description, 18)}
                </p>
              }
            </div>
            <div className="news__footer">
              <a
                className="btn btn-default documentBtn verticalBtn"
                data-toggle="modal"
                data-target={`#modal_${modalID}`}
              >
                Lees meer
              </a>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id={`modal_${modalID}`}
          tabIndex={-1}
          role="dialog"
          aria-labelledby="myModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">{post.title}</h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="newsfeed-modal modal-body">
                <PostOverView post={post} descriptionMarkup={descriptionMarkup} />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

// TODO: YURI RECH. come back better typing

interface HorizontalPostProps {
  post: any
  id: string
}