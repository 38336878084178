import {GetAPIURLTypes} from "../ts/interfaces/api_interfaces.ts";
const getAPIURL: GetAPIURLTypes = (
  communityId,
  show,
  items,
  baseURL = 'https://api.socialschools.eu'
) => {
  let showInt =  !!show ? parseInt(show, 10) : '';
  let finalURL = `${baseURL}/apiv1/public/${communityId}/post/?number_of_items=${items}`;
  if (showInt === 1) {
    finalURL += `&community_and_descendants=true`;
  } else if (showInt === 3) {
    finalURL += `&only_descendants=true`;
  }
  return finalURL;
};

export default getAPIURL;
