import { PureComponent } from 'react';
import Post from './Post';
import './PostList.css';

export default class PostList extends PureComponent<PostListProps> {
  render() {
    const postData = this.props.postData;

    return (
      <div className="ListPostContainer row mt-3">
        <div className="col-12">
          {postData !== null &&
            postData.map((value, key) => (
              <Post post={value} key={key.toString()} id={key.toString()} />
            ))}
        </div>
      </div>
    );
  }
}
interface PostListProps {
  postData: any[] | null
}
