import Moment from 'react-moment';
import 'moment/locale/nl';
import './Event.css';
export default function Event({event}: EventProps) {
  return (
    <div className="eventContainer row">
      <div className="eventStartDate col-md-2 p-0 border rounded-bottom border-top-0">
        <div className="eventMonth bg-primary rounded-top pb-1">
          <h4 className="card-title text-center text-light mb-0">
            <Moment format="MMM" locale="nl">
              {event.start_date}
            </Moment>
          </h4>
        </div>
        <div>
          <p className="eventDate agendaeventDate card-title text-center pt-3">
            <Moment format="DD" locale="nl">
              {event.start_date}
            </Moment>
          </p>
        </div>
      </div>
      <div className="eventDetails col-md-10">
        <p className="startDate">
          Starts <Moment format="dddd MMMM Do YYYY, h:mm:ss" locale="nl">{event.start_date_server_time}</Moment>
        </p>
        <p className="endDate">
          Ends <Moment format="dddd MMMM Do YYYY, h:mm:ss" locale="nl">{event.end_date_server_time}</Moment>
        </p>
      </div>
    </div>
  );
}

export interface EventProps {
  event: {
    start_date: string,
    start_date_server_time: string,
    end_date_server_time: string
  }
}
