export default function Video({ src }: VideoProps) {
    return (
      <video title={`video-${src}`} controls>
        <source src={src} type="video/mp4"></source>
        <track kind="captions" />
      </video>
    );
}

interface VideoProps {
    src: string;
}