import { PureComponent, Fragment} from 'react';
import './OverViewApp.css';
import PostOverViewList from './PostOverViewList';
import {GlobalComponentProps} from "../../ts/interfaces/global_interfaces.ts";

const getAPIURL = (baseURL: string, communityId: string | undefined, show: number) => {
  let finalURL = `${baseURL}/apiv1/public/${communityId}/post/`;
  if (show === 1) {
    finalURL = `${baseURL}/apiv1/public/${communityId}/post/?community_and_descendants=true`;
  } else if (show === 3) {
    finalURL = `${baseURL}/apiv1/public/${communityId}/post/?only_descendants=true`;
  }
  return finalURL;
};


export default class OverViewApp extends PureComponent<GlobalComponentProps, OverViewState> {
  private requestURL: string;
  constructor(props: GlobalComponentProps) {
    super(props);
    let baseURL = 'https://api.socialschools.eu';
    const communityId = this.props.communityId;
    const show = parseInt(this.props.show as string, 10);

    this.requestURL = getAPIURL(baseURL, communityId, show);
    this.state = {
      postsList: null,
      next: null,
      loading: false,
    };
    this.loadOnScroll = this.loadOnScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.loadOnScroll);
    const self = this;
    fetch(this.requestURL).then((response) => {
      return response.json();
    }).then((json) => {
      self.setState({ postsList: json.results, next: json.next });
    });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.loadOnScroll);
  }

  loadOnScroll() {
    const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
    const scrollHeight = (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;
    const clientHeight = document.documentElement.clientHeight || window.innerHeight;
    const scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;

    if (scrolledToBottom) {
      const requestURL = this.state.next;
      if (requestURL !== null) {
        this.setState({ loading: true });
        fetch(requestURL).then((response) => {
          return response.json();
        }).then((json) => {
          //This condition is to check if the postsList is not null.
          //The exclamatory mark is to check if the postsList is not null or undefined.
          if(!!this.state.postsList) {
            const newPostList = [...this.state.postsList, ...json.results];
            this.setState({ postsList: newPostList, next: json.next, loading: false });
            console.log("I am next", json.next);
          } else {
            this.setState({ postsList: [...json.results], next: json.next, loading: false });
          }
        });
      }
    }
  }

  render() {
    const toggl = this.state.loading && this.state.next !== null ? 'visible': 'hide';
    const loaderClass = `fa fa-refresh fa-4x fa-spin ${toggl}`;
    const isEmpty = this.state.postsList === null || this.state.postsList.length === 0;
    return (
      <div className="OverViewContainer" onScroll={this.loadOnScroll.bind(this)}>
        { !isEmpty &&
          <Fragment>
            <PostOverViewList postData={this.state.postsList} />
            <div className="data-loading">
              <i className={loaderClass}></i>
            </div>
          </Fragment>
        }
        { isEmpty && <p>Er zijn geen nieuwe nieuwsberichten.</p>}
      </div>
    );
  }
}

interface OverViewState {
  postsList: any[] | null,
  next: string | null,
  loading: boolean,
}