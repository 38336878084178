import { useEffect } from 'react';
import { useState } from 'react';
import getAPIURL from '../../API/getApiUrl';
import List from './List';
import Pagination from './Pagination';
import './compact.css';
import {GlobalComponentProps} from "../../ts/interfaces/global_interfaces.ts";

function fetchPosts(url: string) {
  return fetch(url).then(response => {
    return response.json();
  });
}
function Compact({ communityId, show, itemCount, url }: GlobalComponentProps) {
  const requestURL = getAPIURL(communityId, show, itemCount);
  const [posts, setPosts] =
    useState<CompactState['posts']>({ results: [], next: '', previous: ''});
  const [loading, setLoading] = useState<CompactState['loading']>(true);

  useEffect(() => {
    fetchPosts(requestURL).then(json => {
      setPosts(() => json);
      setLoading(() => false);
    });
  }, []);

  const loadPosts = (page: string) => {
    if (!['next', 'previous'].includes(page)) return;
    let url = page === 'next' ? posts?.next : posts?.previous;

    setLoading(() => true);

    fetchPosts(url).then(json => {
      setPosts(() => json);
      setLoading(() => false);
    });
  };

  const { results, next, previous } = posts;
  return (
    <>
      {
        results.length < 0 ? <p>Nieuwsberichten laden...</p>:
          <div className="news__items news__items-compact mt-3 mb-4">
            {results.length > 0 && <List posts={results} />}
            <Pagination
              url={url}
              previousUrl={previous}
              nextUrl={next}
              loadPosts={loadPosts}
            />
          </div>
      }
    </>
  );
}

export default Compact;


interface CompactState {
  posts: PostsTypes;
  loading: boolean;
}

interface PostsTypes {
  results: string[];
  next: string;
  previous: string;
  url?: string;
}